import { Box, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function PostArchive() {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Schachclub ML Kastellaun - ' + location.pathname.replace('/', '');
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '1%',
        }}>
        <Paper
          elevation={3}
          sx={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '1000px',
            mr: 2,
            ml: 2,
          }}>
          <Typography component="h1" variant="h5" sx={{ alignSelf: 'center' }}>
            Archivierte Beiträge
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
}

export default PostArchive;
