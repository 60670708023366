import { LightMode, DarkMode, Logout } from '@mui/icons-material';
import { Box } from '@mui/material';
import { socialIconSxProps } from 'config/socialIconSxProps';
import { AuthContext } from 'contexts/auth.context';
import { ThemeContext } from 'contexts/theme.context';
import { useContext } from 'react';

function FunctionIconsList() {
  const { user, userLogout } = useContext(AuthContext);
  const { colorMode, setColorMode } = useContext(ThemeContext);

  return (
    <>
      <Box>
        {colorMode === 'dark' ? (
          <LightMode
            sx={{ cursor: 'pointer', ...socialIconSxProps }}
            onClick={() => setColorMode('light')}
          />
        ) : (
          <DarkMode
            sx={{ cursor: 'pointer', ...socialIconSxProps }}
            onClick={() => setColorMode('dark')}
          />
        )}
      </Box>
      {user?.is_admin && (
        <Box>
          <Logout
            className="logoutIcon"
            onClick={userLogout}
            sx={{ cursor: 'pointer', ...socialIconSxProps }}
          />
        </Box>
      )}
    </>
  );
}

export default FunctionIconsList;
