import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar.component';
import Home from './pages/home.page';
import AdminLoginPage from 'pages/admin-login/admin-login.page';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import BasePage from 'pages/base/base.page';
import { navStructure } from 'config/navbarItems';
import PostArchive from 'pages/archive/post-archive.page';
import header from 'assets/header_background.jpg';
import ManagePostsPage from 'pages/news/managePosts.page';
import NewsPage from 'pages/news/news.page';
import { useContext } from 'react';
import NoAccessPage from 'pages/noAccess.page';
import { AuthContext } from 'contexts/auth.context';
import ConfirmDialog from 'components/dialog/dialog.component';
import { ThemeContext, LightTheme, DarkTheme } from 'contexts/theme.context';
import TeamsPage from 'pages/teams/teams.page';

function App() {
  const { user } = useContext(AuthContext);
  const { colorMode } = useContext(ThemeContext);

  function generateRoutes(structure) {
    return Object.keys(structure).map((key) => {
      const item = structure[key];
      if ('link' in item && !item.custom) {
        return (
          <Route
            key={key}
            path={item.link}
            element={
              (item.admin && user?.is_admin) || !item.admin ? (
                <BasePage key={key} Title={item.title} />
              ) : (
                <NoAccessPage />
              )
            }
          />
        );
      } else if ('dropdown' in item) {
        return generateRoutes(item.dropdown);
      }
    });
  }

  return (
    <ThemeProvider theme={colorMode === 'light' ? LightTheme : DarkTheme}>
      <CssBaseline />
      <Router>
        <Box
          sx={{
            position: 'relative',
            height: { xs: '100%', lg: '85vh' },
            pt: '1%',
            bgcolor: { xs: '#2c2f2b', lg: 'transparent' },
          }}>
          <Container maxWidth="xl">
            <Navbar />
          </Container>
          <Box
            component="img"
            src={header}
            alt="header_background"
            sx={{
              position: { xs: 'static', lg: 'absolute' },
              top: 0,
              left: 0,
              width: '100%',
              height: '85vh',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Container maxWidth="xl" sx={{ mb: '1%', pt: '1%' }}>
          <Box>
            <Routes>
              <Route key="/" path="/" element={<Home />} />
              <Route key="/admin-login" path="/admin-login" element={<AdminLoginPage />} />
              <Route key="/news" path="/news" element={<NewsPage />} />
              <Route key="/news/:id" path="/news/:id" element={<NewsPage />} />
              <Route
                key="/news/verwalten"
                path="/news/verwalten"
                element={user?.is_admin ? <ManagePostsPage /> : <NoAccessPage />}
              />
              <Route
                key="/news/verwalten/:id?"
                path="/news/verwalten/:id?"
                element={user?.is_admin ? <ManagePostsPage /> : <NoAccessPage />}
              />
              <Route key="/teams/:team" path="/teams/:team" element={<TeamsPage />} />
              <Route key="/archive/posts" path="/archive/posts" element={<PostArchive />} />
              {generateRoutes(navStructure)}
            </Routes>
          </Box>
        </Container>
      </Router>
      <ConfirmDialog />
    </ThemeProvider>
  );
}

export default App;
