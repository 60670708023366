import { AppBar, Box, IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import NavbarDesktop from './navbar.desktop.component';
import NavbarMobile from './navbar.mobile.component';
import logo from 'assets/scmlkastellaun_logo.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import FunctionIconsList from './functionIconsList.component';

function Navbar() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        position: 'relative',
        zIndex: 1,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {/* Logo */}
        <Link to="/">
          <Box
            component="img"
            sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }}
            src={logo}
            alt="logo"
          />
        </Link>
        {/* This will be displayed on larger screens */}
        <NavbarDesktop />
        {/* Control icons for small screens */}
        <Box sx={{ display: { md: 'flex', lg: 'none' } }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <FunctionIconsList />
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setIsMobileNavOpen(isMobileNavOpen ? false : true)}
              color="inherit"
              sx={{ mb: 0.8 }}>
              <MenuIcon sx={{ fontSize: '35px' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* This will be displayed on small screens */}
      {isMobileNavOpen && <NavbarMobile setIsMobileNavOpen={setIsMobileNavOpen} />}
    </AppBar>
  );
}
export default Navbar;
