import { useEffect, useRef } from 'react';
import { LinkBubbleMenu, RichTextEditor, RichTextEditorRef } from 'mui-tiptap';
import { useExtensions } from './rte-editor-extensions';
import RteEditorControls from './rte-editor-controls';
import { Box } from '@mui/material';
import { imageUpload } from 'functions/fileUpload.functions';

interface RteEditorProps {
  content: string;
  onChange?: (content: string) => void;
  placeholder?: string;
  editMode?: boolean;
  showControls?: boolean;
  resetKey?: number;
}

function RteEditorComponent({
  content,
  placeholder = '',
  onChange,
  editMode = true,
  showControls = true,
  resetKey,
  ...props
}: RteEditorProps) {
  const rteRef = useRef<RichTextEditorRef>(null);
  const initialContentSet = useRef(false);

  useEffect(() => {
    if (rteRef.current && rteRef.current.editor && !initialContentSet.current) {
      rteRef.current.editor.commands.setContent(content);
      initialContentSet.current = true;
    }
  }, [content]);

  useEffect(() => {
    if (rteRef.current && rteRef.current.editor) {
      rteRef.current.editor.commands.setContent(content);
    }
  }, [resetKey]);

  function handleImageInsert(files) {
    const imageContentToInsert = files.map((imageAttrs) => ({
      type: rteRef.current?.editor?.schema.nodes.image.name,
      attrs: imageAttrs,
    }));
    rteRef.current?.editor?.commands.insertContent(imageContentToInsert);
    return true;
  }

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <RichTextEditor
        ref={rteRef}
        extensions={useExtensions({ placeholder: placeholder })}
        content={content}
        editable={editMode}
        onUpdate={({ editor }) => {
          if (onChange) {
            onChange(editor.getHTML());
          }
        }}
        renderControls={showControls ? () => <RteEditorControls rteRef={rteRef} /> : undefined}
        RichTextFieldProps={{
          variant: editMode ? 'outlined' : 'standard',
          MenuBarProps: {
            hide: !editMode,
          },
        }}
        editorProps={{
          handlePaste: function (view, event) {
            const items = Array.from(event.clipboardData?.items || []);
            const uploadedFiles = items.map(async (item) => {
              if (item.type.indexOf('image') === 0) {
                const file = item.getAsFile();
                if (file) {
                  return await imageUpload(file);
                }
                return true;
              }
              return null;
            });
            Promise.all(uploadedFiles).then((response) => {
              handleImageInsert(response);
            });
            return false;
          },
          handleDrop: function (view, event, slice, moved) {
            if (!moved && event.dataTransfer && event.dataTransfer.files.length > 0) {
              const files = Array.from(event.dataTransfer.files) as File[];
              const uploadedFiles = files.map(async (file: File) => {
                if (file.type.indexOf('image') === 0) {
                  return await imageUpload(file);
                }
                return null;
              });
              Promise.all(uploadedFiles).then((response) => {
                const imageContentToInsert = response.map((imageAttrs) => ({
                  type: rteRef.current?.editor?.schema.nodes.image.name,
                  attrs: imageAttrs,
                }));
                rteRef.current?.editor?.commands.insertContent(imageContentToInsert);
                return true;
              });
              return true;
            } else {
              return false;
            }
          },
          transformPastedHTML(html) {
            return html.replace(/<img.*?src="(?<imgSrc>.*?)".*?>/gm, function (match, imgSrc) {
              console.log('Checking image source:', imgSrc);
              if (
                imgSrc.startsWith(
                  'https://firebasestorage.googleapis.com/v0/b/schachclub-kastellaunde.appspot.com', // TODO: Replace with new storage API URL / extract image information and upload to new storage
                )
              ) {
                return match;
              }
              console.log('Image was pasted from another source. Ignoring it.');
              return '';
            });
          },
        }}
        {...props}>
        {() => (
          <>
            <LinkBubbleMenu
              labels={{
                viewLinkEditButtonLabel: 'Bearbeiten',
                viewLinkRemoveButtonLabel: 'Entfernen',
                editLinkAddTitle: 'Link hinzufügen',
                editLinkEditTitle: 'Link bearbeiten',
                editLinkCancelButtonLabel: 'Abbrechen',
                editLinkSaveButtonLabel: 'Speichern',
              }}
            />
          </>
        )}
      </RichTextEditor>
    </Box>
  );
}

export default RteEditorComponent;
