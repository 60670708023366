import { Text } from '@tiptap/extension-text';
import { Bold } from '@tiptap/extension-bold';
import { Underline } from '@tiptap/extension-underline';
import { TextStyle } from '@tiptap/extension-text-style';
import { Document } from '@tiptap/extension-document';
import { Paragraph } from '@tiptap/extension-paragraph';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import { Strike } from '@tiptap/extension-strike';
import { Link } from '@tiptap/extension-link';
import { Color } from '@tiptap/extension-color';
import { Highlight } from '@tiptap/extension-highlight';
import { TextAlign } from '@tiptap/extension-text-align';
import { CodeBlock } from '@tiptap/extension-code-block';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { FontSize, HeadingWithAnchor, LinkBubbleMenuHandler, TableImproved } from 'mui-tiptap';
import type { EditorOptions } from '@tiptap/core';
import { useMemo } from 'react';
import { Blockquote } from '@tiptap/extension-blockquote';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { FontFamily } from '@tiptap/extension-font-family';
import { HardBreak } from '@tiptap/extension-hard-break';
import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { Subscript } from '@tiptap/extension-subscript';
import { Superscript } from '@tiptap/extension-superscript';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { TaskItem } from '@tiptap/extension-task-item';
import { TaskList } from '@tiptap/extension-task-list';
import InlineImageExtension from './inlineImage.rte-extension';
import Placeholder from '@tiptap/extension-placeholder';
import PdfExtension from './pdf.rte-extension';
import { ClearfixExtension } from './clearfix.rte-extension';

export type UseExtensionsOptions = {
  /** Placeholder hint to show in the text input area before a user types a message. */
  placeholder?: string;
};

const CustomLinkExtension = Link.extend({
  inclusive: false,
}).configure({
  autolink: true,
  linkOnPaste: true,
  openOnClick: false,
});

export function useExtensions({
  placeholder,
}: UseExtensionsOptions = {}): EditorOptions['extensions'] {
  return useMemo(() => {
    return [
      Text,
      Document,
      History,
      Paragraph,
      Placeholder.configure({ placeholder: placeholder }),
      HeadingWithAnchor,
      FontSize,
      Bold,
      Italic,
      Underline,
      Strike,
      TextStyle,
      CustomLinkExtension,
      LinkBubbleMenuHandler,
      Color,
      Highlight,
      BulletList,
      OrderedList,
      ListItem,
      TextAlign.configure({
        types: ['heading', 'paragraph', 'image'],
      }),
      Blockquote,
      Dropcursor,
      FontFamily,
      HardBreak,
      HorizontalRule,
      Subscript,
      Superscript,
      TableCell,
      TableHeader,
      TableRow,
      TaskItem,
      TaskList,
      CodeBlock,
      TableImproved,
      InlineImageExtension,
      ClearfixExtension,
      PdfExtension,
    ];
  }, [placeholder]);
}

export function useDefaultExtensions({
  placeholder,
}: UseExtensionsOptions = {}): EditorOptions['extensions'] {
  return useMemo(() => {
    return [Text, Document, History];
  }, [placeholder]);
}
