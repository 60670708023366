import { Box } from '@mui/material';
import ContentComponent from 'components/input/content.component';
import TitleComponent from 'components/text/title.component';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface BasePageProps {
  Title?: string;
}

function BasePage({ Title }: BasePageProps) {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Schachclub ML Kastellaun - ' + Title ?? location.pathname.replace('/', '');
  });

  return (
    <Box>
      <TitleComponent text={Title ? Title : ''} />
      <ContentComponent route={location.pathname.replace('/', '')}></ContentComponent>
    </Box>
  );
}

export default BasePage;
