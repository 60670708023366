import { Box, Button } from '@mui/material';
import RteEditorComponent from 'components/rte-editor/rte-editor.component';
import { AuthContext } from 'contexts/auth.context';
import ContentData from 'dtos/contentData';
import { createContent, updateContent, getContent } from 'functions/pageContent.functions';
import { useContext, useEffect, useState } from 'react';
import ButtonControlBar from './buttonControlBar.component';
import CustomButton from './button.component';
import { DialogContext } from 'contexts/dialog.context';

type ContentComponentProps = {
  route: string;
};

function ContentComponent({ route }: ContentComponentProps) {
  const { user } = useContext(AuthContext);
  const [isEditMode, setIsEditMode] = useState(false);

  const [pageContent, setPageContent] = useState<ContentData>({
    id: '',
    content: '',
    creationDate: '',
    lastUpdateDate: '',
    route: route,
  });
  const [initialPageContent, setInitialPageContent] = useState<ContentData>(pageContent);
  const [rteResetKey, setRteResetKey] = useState(0);

  const { setDialogProps } = useContext(DialogContext);

  useEffect(() => {
    getContent(route).then((response) => {
      if (response[0]) {
        setPageContent(response[0]);
        setInitialPageContent(response[0]);
      }
    });
  }, []);

  async function onChange(content: string) {
    setPageContent({ ...pageContent, content: content });
  }

  async function handleSubmit(confirm: boolean = false) {
    if (!confirm && pageContent.id) {
      setDialogProps({
        open: true,
        message: 'Inhalt Aktualisieren?',
        content: 'Vorherige Versionen können nicht wiederhergestellt werden!',
        onConfirm: () => handleSubmit(true),
      });
    } else {
      console.log(pageContent);
      if (!pageContent.id) {
        await createContent(pageContent);
      } else {
        await updateContent(pageContent.id, pageContent);
      }
      setInitialPageContent(pageContent);
      setIsEditMode(false);
    }
  }

  async function handleRevert(confirm: boolean = false) {
    if (!confirm && pageContent.content !== initialPageContent.content) {
      setDialogProps({
        open: true,
        message: 'Eingaben wirklich verwerfen?',
        content: 'Diese Aktion kann nicht rückgängig gemacht werden!',
        onConfirm: () => handleRevert(true),
      });
    } else {
      setPageContent(initialPageContent);
      setRteResetKey(rteResetKey ? 0 : 1);
    }
  }

  async function handleCancel(confirm: boolean = false) {
    console.log(pageContent.content, initialPageContent.content);
    if (!confirm && pageContent.content !== initialPageContent.content) {
      setDialogProps({
        open: true,
        message: 'Abbrechen Bestätigen',
        content: 'Nicht gespeicherte Änderungen gehen verloren!',
        onConfirm: () => handleCancel(true),
      });
    } else {
      setPageContent(initialPageContent);
      setRteResetKey(rteResetKey ? 0 : 1);
      setIsEditMode(false);
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {user?.is_admin && !isEditMode && (
        <ButtonControlBar>
          <Button
            onClick={() => setIsEditMode(true)}
            sx={{
              flexGrow: 1,
              bgcolor: 'success.main',
              '&:hover': {
                bgcolor: 'success.dark',
              },
              color: 'black',
              maxWidth: '200px',
            }}>
            Inhalt Bearbeiten
          </Button>
        </ButtonControlBar>
      )}
      <RteEditorComponent
        content={pageContent.content}
        editMode={isEditMode}
        onChange={onChange}
        resetKey={rteResetKey}
      />
      {user?.is_admin && isEditMode && (
        <ButtonControlBar sx={{ mt: '4vh' }}>
          <CustomButton
            onClick={() => handleCancel(false)}
            sx={{
              flexGrow: 1,
              bgcolor: 'error.main',
              '&:hover': {
                bgcolor: 'error.dark',
              },
              color: 'black',
              maxWidth: '200px',
            }}>
            Abbrechen
          </CustomButton>
          <CustomButton
            onClick={() => handleRevert()}
            sx={{
              flexGrow: 1,
              bgcolor: 'warning.main',
              '&:hover': {
                bgcolor: 'warning.dark',
              },
              color: 'black',
            }}>
            Eingaben Verwerfen
          </CustomButton>
          <CustomButton
            onClick={() => handleSubmit()}
            sx={{
              flexGrow: 1,
              bgcolor: 'success.main',
              '&:hover': {
                bgcolor: 'success.dark',
              },
              color: 'black',
            }}>
            Speichern
          </CustomButton>
        </ButtonControlBar>
      )}
    </Box>
  );
}

export default ContentComponent;
