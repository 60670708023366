import { Divider, Paper, Typography } from '@mui/material';
import { NodeViewWrapper } from '@tiptap/react';
import { Link } from 'react-router-dom';

function RtePdfComponent({ node }) {
  const { file } = node.attrs;

  return (
    <NodeViewWrapper>
      <Divider sx={{ mt: 2, mb: 1 }} />
      <Typography>
        Falls die PDF-Datei nicht angezeigt wird, kann sie&nbsp;
        <Link to={file} target="_blank">
          hier
        </Link>
        &nbsp;geöffnet werden.
      </Typography>
      <Paper
        elevation={10}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: { lg: '700px', md: '600px', sm: '500px', xs: '360px' },
          height: { lg: '1050px', md: '900px', sm: '750px', xs: '600px' },
          mt: 1,
        }}>
        <object data={file} type="application/pdf" width="100%" height="100%">
          <Typography>Die PDF-Datei konnte nicht angezeigt werden.</Typography>
        </object>
      </Paper>
    </NodeViewWrapper>
  );
}

export default RtePdfComponent;
